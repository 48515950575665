@import "variables";

.footer {
  width: 100%;
  height: auto;
  display: flex;
  place-items: center;
  justify-content: space-between;
  background-color: $secondaryColor;
  padding: 1vw 2vw;

  .copyright_section {

    .copyright_content {
      margin-bottom: 0;
      font-size: 1.7rem;
      font-weight: 400;
      color: $primaryColor;
    }
  }

  .footer_socialMedia {
    display: flex;
    gap: 20px;

    .social_media {
      @include flex(row, center);
      width: 3rem;
      height: 2.6rem;

      a {
        width: 3rem;
        height: 2.6rem;
        img {
          object-fit: scale-down;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;


    .copyright_section {
      .copyright_content {
        font-size: 1.3rem;
        text-align: left;
      }
    }

    .footer_socialMedia {
      .social_media {
        width: 2.5rem;
        height: 2rem;
        text-align: center;

        a {
          width: 2.5rem;
          height: 2rem;
          img {
            object-fit: scale-down;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
