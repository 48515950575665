@import "variables";

.executives {
  display: grid;
  .executive_head_section {
    @include headingStyle($secondaryColor);
    width: 100%;
    background-color: $primaryColor;
    margin: 0 auto;
    text-align: center;

    
    .executive_head_container {
      margin: 5rem auto;
      padding-top: 5rem;
      // border: 2px solid red;
      width: 60%;
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 1fr));
      grid-column-gap: 16px;
      grid-row-gap: 30px;
      
      &.two-items {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        justify-content: space-around; // Center the two items within the container
      }

      .head_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $secondaryColor;

        .head_image {
          width: 120px;
          height: 120px;
          border-radius: 50%;

          img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
          }
        }

        .executive_post_holder {
          padding: 2rem 0;

          p {
            line-height: 2.7rem;
            margin-bottom: 0;

            &:first-child {
              font-weight: 300;
              font-size: 1.8rem;
            }
            &:last-child {
              font-weight: 500;
              font-size: 1.5rem;
              letter-spacing: 2px;
            }
            text-align: center;
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .executives {
//     .executive_head_section {
//       .executive_head_container {
//         grid-template-columns: repeat(2, minmax(200px, 1fr));
//         .head_image {
//           width: 90px;
//           height: 90px;

//           img {
//             width: 90px;
//             height: 90px;
//           }
//         }
//       }
//     }
//   }
// }


@media only screen and (max-width: 768px) {
  .executives {
    .executive_head_section {
      .executive_head_container {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        grid-template-rows: auto auto; /* Two rows, one for the top items and one for the bottom */
        grid-gap: 16px; /* Gap between items */

        .head_box {
          &:nth-child(3) {
            grid-column: 1 / 3; /* Making the third item span both columns */
            justify-self: center; /* Centering it horizontally */
          }
        }

        .head_image {
          width: 90px;
          height: 90px;

          img {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
}
