@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import "variables";

.layout {
    width: 100%;
    position: relative;
    display: grid;
    font-size: 70%;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-weight: 300;
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
}

.card {
    cursor: pointer;
}

a {
    text-decoration: none;
}
li {
    list-style: none;
}
h2 {
    font-size: 2.2rem;
    font-weight: 500;
}

h4 {
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .layout {
        width: 100%;
        display: grid;
    }
}

