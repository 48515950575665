@import "variables";
.nav_main {
  margin: 0;
  padding: 1rem;
  top: -3px;
  position: sticky;
  display: flex;
  width: 100%;
  border: 0;
  outline: 0;
  align-items: center;
  background-color: $primaryColor;
  color: $secondaryColor;
  top: 0;
  z-index: 200;
  box-shadow: 2px 10px 20px rgba(190, 190, 190, 0.2);

  .humburger_icon {
    visibility: hidden;
  }

  .nav_logo {
    flex: 1;

    a {
      img {
        width: 45px;
        height: 30px;
      }
    }
  }

  .nav_menu {
    display: flex;
    // align-items: center;
    margin: 0;

    li {
      a {
        display: flex;
        margin: 0 1rem;
        padding: 0.2rem 1.3rem;
        font-size: 1.8rem;
        cursor: pointer;
        position: relative;
        font-weight: 300;
        z-index: 2;
        color: $secondaryColor;
        transition: color 0.5s;
        
        
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 5px;
          position: absolute;
          top: 100%;
          left: 50%;
          outline: none;
          border: none;
          background-color: $secondaryColor;
          transform: translate(-50%, -50%);
          transition: top 0.5s, opacity 0.5s;
          z-index: -1;
          opacity: 0;
        }

        &:hover {
          font-weight: 400;
          color: $primaryColor;

          &::after {
            top: 50%;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav_main {
    .nav_logo {
      a {
        img {
          width: 50px;
          height: 40px;
        }
      }
    }

    .humburger_icon {
      visibility: visible;
    }
    .nav_menu {
      display: none;
    }
    .mobile_nav_menu {
      position: absolute;
      display: block;
      list-style: none;
      background-color: $primaryColor;
      left: 0;
      top: 5rem;
      transition: all 0.5s ease-in-out;
      width: 100%;

      li {
        width: 100%;
        transition: all 0.5s ease;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 2rem;
        
        &:first-child {
          margin-top: 2rem;
        }

        a {
          color: $secondaryColor;
          letter-spacing: 1px ;
          font-weight: 300;
        }
      }
    }
  }
}


